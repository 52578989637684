import React, { Component } from 'react';
import './AddCategory.css';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { env } from '../../environment';
import axios from 'axios';

class AddCategory extends Component {

    constructor(props){
        super(props);
        this.state ={
            category : "",
            colorCode : "",
            id : "",
            showMessage : false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            document.title = 'Edit Category';
            axios.get( env + `category/getCategoriesById?id=` + this.props.match.params.id)
            .then(res => {
                const category = res.data;
                this.setState({ id : this.props.match.params.id, category : category.name, colorCode : category.color });
            })
        }
        else{
            document.title = 'Add Category';
        }

    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.params) {
            document.title = 'Add Category';
            this.setState({ category : ""});
        }
    }

    addCategory(){
        const addCategory = this.props.location.pathname.includes("addCategory")
        // Add Category
        if(addCategory){
            axios.post( env + `category/saveCategory`,{name: this.state.category, color: this.state.colorCode})
            .then(res => {
                this.setState({ showMessage : true})
                setTimeout(() => { this.setState({ showMessage : false }); this.props.history.push("/home"); }, 1000);
                axios.post( env + `subcategory/save`,{categoryData : res.data, subCategoryName: "Default"})
                    .then(res => {
                        console.log("Setting default Category");
                    })
            })
        
        }
        // Edit Category
        else{
            const data = {
                "id": parseInt(this.state.id),
                "name": this.state.category,
                "color": this.state.colorCode
              }
            axios.put( env + `category/updateCategory`, data)
            .then(res => {
                this.setState({ showMessage : true})
                setTimeout(() => { this.setState({ showMessage : false }); this.props.history.push("/home"); }, 1000);
            })
        }
    
    }

    deleteCategory(){
        axios.delete( env + `category/delete?id=` + this.props.match.params.id)
            .then(res => {
                this.props.history.push("/home");
            })
    }
  
    render () {
          const addCategory = this.props.location.pathname.includes("addCategory")
          return (
            <div className="categoryContainer">
            <div className="categoryHeader">{addCategory ? "Add" : "Edit"} Category</div>
            <Form>
                <FormGroup>
                    <Label for="category">Category Name</Label>
                    <Input type="text" name="category" id="category" value={this.state.category} onChange= {(e) => this.setState({category : e.target.value})}/>
                </FormGroup>
                <FormGroup>
                    <Label for="footerColorCode">Category Color Code</Label>
                    <Input type="text" name="colorCode" id="colorCode" placeholder="#FFFFFF" value={this.state.colorCode} onChange= {(e) => this.setState({colorCode : e.target.value})}/>
                </FormGroup>
                
                {addCategory ? (
                    <div style={{textAlign:'center', marginBottom: '15px'}}><Button color="primary" onClick={() => this.addCategory()} disabled={ this.state.category.trim() === "" || this.state.colorCode.trim() === "" }>Submit</Button></div>
                ) : (
                    <div style={{textAlign:'center', marginBottom: '15px'}}><Button color="warning" style={{color:"white"}} onClick={() => this.addCategory()} disabled={ this.state.category.trim() === "" }>Update</Button> <Button color="danger" onClick={() => this.deleteCategory()}>Delete</Button></div>
                )}
                <div className={this.state.showMessage ? 'showMessage' : 'hideMessage'}>Updated Successfully</div>
            </Form>
          </div>
          )
      }
  }
  
  export default withRouter(AddCategory)